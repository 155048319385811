<template>
  <Backoffice :loginRequired="false">
    <div class="container">
      <div class="logo">

      </div>
      <h2>Error 404</h2>
      <p>Não conseguimos encontrar esta página!</p>
    </div>
  </Backoffice>
</template>

<script>
  import Backoffice from '../../../Template/Backoffice'

  export default {
    name: 'Error404',
    components: {
      Backoffice
    },
    data () {
      return {
      }
    }
  }
</script>

<style lang="scss" scoped>
.container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
}
h2{
    opacity: 0.9;
    font-family: 'Barlow';
    font-size: 24.8px;
    font-weight: 600;
    text-align: center;
    color: #eb791e;
}
p{
    width: 220px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #eb791e;
}
</style>
